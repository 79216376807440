<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.flights')} / ${$t('lbl.list')}` }}</v-card-title>
        <v-card-text><List></List></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import List from './form/List.vue'

export default {
  components: {
    List,
  },
}
</script>
